import CustomDialog from "../CustomDialog/CustomDialog";
import CustomDialogActions from "../CustomDialog/CustomDialogActions";

import { ReactNode, useRef } from "react";
import { useTranslation } from "react-i18next";

import SaveAltIcon from "@mui/icons-material/SaveAlt";
import Tooltip from "@mui/material/Tooltip";

import LoadConfigurationFormContent from "./LoadConfigurationFormContent";

export default function LoadConfigurationDialog(): ReactNode {
  const { t } = useTranslation();
  const submitFormRef = useRef<HTMLFormElement>(null);

  return (
    <CustomDialog
      buttonProps={{
        sx: { paddingRight: 0 },
        size: "large",
        startIcon: (
          <Tooltip title={t("load")} placement="top" arrow>
            <SaveAltIcon sx={{ rotate: "180deg" }} />
          </Tooltip>
        ),
        variant: "text",
      }}
      dialog={{
        title: t("load_configuration_dialog.title"),
        actions: ({ closeDialog }) => (
          <CustomDialogActions
            textCancel={t("load_configuration_dialog.cancel")}
            textConfirm={t("load_configuration_dialog.search")}
            onCancel={() => {
              closeDialog();
            }}
            onConfirm={() => submitFormRef?.current?.requestSubmit()}
          />
        ),
        content: <LoadConfigurationFormContent submitFormRef={submitFormRef} />,
      }}
    />
  );
}
