import { theme } from "./theme/theme";

export function logVersion(): void {
  console.log(
    `%c                                                
                 FE version ${__APP_VERSION__}               
                                                `,
    `background: ${theme.palette.primary.main}; color: #FFF; padding: 3px; font-weight: bold; margin: -3px;`,
  );
}
