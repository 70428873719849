import { ReactNode, useState } from "react";

import Paper from "@mui/material/Paper";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import { ConfigurationTabsContent } from "@plansoft-configuration-renderer/components";
import { CustomLayoutProps } from "@plansoft-configuration-renderer/layout";
import { useCustomTranslation } from "@plansoft-configuration-renderer/localization";

/**
 * Props for {@linkcode ConfigurationTabs}
 * @category UI
 */
export type ConfigurationTabsProps = CustomLayoutProps & {
  children: ReactNode;
  tabs: string[];
  orientation?: "vertical" | "horizontal";
  /**
   * Used in layout editor
   */
  showAllTabs?: boolean;
};

/**
 * Tab control on configuration level
 * @category UI
 * @param props
 * @returns
 */
export function CustomConfigurationTabs(
  props: ConfigurationTabsProps,
): JSX.Element {
  const { tabs, custom } = props;

  const { t } = useCustomTranslation();

  const [activeTab, setActiveTab] = useState(0);

  const activeTabId = tabs[activeTab];

  switch (custom?.variant) {
    case "main":
      return (
        <>
          <Tabs
            orientation={props.orientation}
            variant="scrollable"
            onChange={(event, newValue) => setActiveTab(newValue)}
            value={activeTab}
            sx={{
              width: "90%",
              margin: "0 auto",
              "& .MuiTabs-indicator": {
                display: "none",
              },
            }}
          >
            {tabs.map((tab, tabIndex) => (
              <Tab
                key={tabIndex}
                value={tabIndex}
                label={t(tab, tab)}
                sx={{
                  marginX: 1,
                  border: ({ palette }) => `1px solid ${palette.grey[300]}`,
                  fontWeight: "bold",
                  backgroundColor: "primary.light",
                  "&.Mui-selected": {
                    color: "primary.main",
                  },
                }}
              />
            ))}
          </Tabs>
          {props.showAllTabs ? (
            props.children
          ) : (
            <ConfigurationTabsContent activeTab={activeTabId} />
          )}
        </>
      );

    default:
      return (
        <>
          <Tabs
            orientation={props.orientation}
            onChange={(event, newValue) => setActiveTab(newValue)}
            value={activeTab}
          >
            {tabs.map((tab, tabIndex) => (
              <Tab key={tabIndex} value={tabIndex} label={t(tab, tab)} />
            ))}
          </Tabs>
          {props.showAllTabs ? (
            props.children
          ) : (
            <ConfigurationTabsContent activeTab={activeTabId} />
          )}
        </>
      );
  }
}

/**
 * Props for {@linkcode ConfigurationTabItem}
 * @category UI
 */
export type ConfigurationTabItemProps = CustomLayoutProps & {
  tabId: string;
  children: ReactNode;
};

/**
 * Tab item for {@linkcode ConfigurationTabItem}
 * @category UI
 * @param props
 * @returns
 */

export function CustomConfigurationTabItem(
  props: ConfigurationTabItemProps,
): JSX.Element {
  const { tabId } = props;
  return (
    <Paper
      sx={{
        padding: "2rem 2rem 0 2rem",
        marginTop: 2,
        height: "calc(100vh - 2rem - 73px - 40px - 3rem - 1rem)",
        overflow: "auto",
      }}
    >
      <Typography variant="h6">{tabId}</Typography>
      {props.children}
    </Paper>
  );
}
