import React, { ReactNode, useEffect } from "react";

import { ExpandMore, Info } from "@mui/icons-material";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import type { SxProps } from "@mui/system";
import { useConfigurationAttributeScrolling } from "@plansoft-configuration-renderer/components";
import {
  useConfigurationAttributeName,
  useCsAttribute,
  useCsValueById,
  useLocalSelectValue,
} from "@plansoft-configuration-renderer/framework";

export interface AttributeWrapperProps {
  children: ReactNode;
}

/**
 * Generic wrapper for attributes
 *
 * Wraps attribute headline and control element
 */
export function CustomAttributeWrapper(
  props: AttributeWrapperProps,
): JSX.Element {
  const { id, isAllowed, path, isComplete, isRequired } = useCsAttribute();
  const attribute = useCsAttribute();
  const elementRef = useConfigurationAttributeScrolling();

  const notAllowedProps: SxProps = isAllowed
    ? {}
    : {
        pointerEvents: "none",
        opacity: 0.75,
      };

  const selectedValueId = useLocalSelectValue();
  const selectedValue = useCsValueById(selectedValueId);

  const labelText = useConfigurationAttributeName();

  const [expanded, setExpanded] = React.useState(true);

  const theme = useTheme();

  const handleExpansion = (): void => {
    setExpanded((prevExpanded) => !prevExpanded);
  };

  useEffect(() => {
    setExpanded(!(selectedValueId && attribute.isComplete));
  }, [selectedValueId, attribute.isComplete]);

  return (
    <Accordion
      expanded={expanded}
      onChange={handleExpansion}
      sx={{
        ...notAllowedProps,
      }}
    >
      <AccordionSummary
        id={path}
        className="attribute-wrapper"
        expandIcon={<ExpandMore />}
        sx={{
          flexDirection: "row-reverse",
          padding: 0,
          gap: 2,
          borderColor:
            attribute.isRequired && !selectedValueId && !expanded
              ? theme.palette.warning.main
              : undefined,
        }}
      >
        <Box display={"inline-flex"} sx={{ gap: 1 }}>
          <Typography>
            {labelText} {attribute.isRequired ? "*" : undefined}
          </Typography>
          {attribute.description && (
            <Tooltip title={attribute.description} placement={"right"} arrow>
              <Info color={"primary"} />
            </Tooltip>
          )}
        </Box>
        <Typography color={"primary"} sx={{ fontWeight: "bold" }}>
          {selectedValue?.name || selectedValue?.value}
        </Typography>
      </AccordionSummary>
      <AccordionDetails
        ref={elementRef}
        data-is-complete={isComplete}
        data-is-required={isRequired}
        data-attribute-path={path}
        data-attribute-id={id}
        sx={{
          transition: theme.transitions.create(["opacity"], {
            duration: theme.transitions.duration.standard,
          }),
          opacity: expanded ? 1 : 0,
        }}
      >
        {props.children}
      </AccordionDetails>
    </Accordion>
  );
}
