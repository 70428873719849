import { ReactNode } from "react";
import { useTranslation } from "react-i18next";

import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";

type Props = {
  onCancel: () => void;
  textCancel: string;
  cancelDisabled: boolean;
  onConfirm: () => void;
  textConfirm: string;
  confirmDisabled: boolean;
};

export default function CustomDialogActions({
  onCancel,
  textCancel,
  cancelDisabled,
  onConfirm,
  textConfirm,
  confirmDisabled,
}: Partial<Props>): ReactNode {
  const { t } = useTranslation();

  return (
    <DialogActions onClick={(e) => e.stopPropagation()}>
      {onConfirm && (
        <Button
          sx={{ width: "125px" }}
          variant="outlined"
          onClick={(e) => {
            e.stopPropagation();
            onConfirm();
          }}
          disabled={confirmDisabled}
        >
          {textConfirm || t("button.save")}
        </Button>
      )}
      {onCancel && (
        <Button
          sx={{ width: "125px" }}
          variant="outlined"
          onClick={(e) => {
            e.stopPropagation();
            onCancel();
          }}
          disabled={cancelDisabled}
        >
          {textCancel || t("button.cancel")}
        </Button>
      )}
    </DialogActions>
  );
}
