import { ReactNode, RefObject } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { DatePicker } from "@mui/x-date-pickers";

type LoadConfigurationFormContentProps = {
  submitFormRef: RefObject<HTMLFormElement>;
};

export default function LoadConfigurationFormContent({
  submitFormRef,
}: LoadConfigurationFormContentProps): ReactNode {
  const { t } = useTranslation();
  const { register, handleSubmit, setValue } = useForm();

  // TODO: [KS] replace with actual response data
  const formFields = [
    "Name",
    "GUID",
    "Beschreibung",
    "Artikelnummer",
    "Kurztext",
    "Ersteller",
  ];

  const datePickers = [
    { label: t("load_configuration_dialog.startDate"), name: "startDate" },
    { label: t("load_configuration_dialog.endDate"), name: "endDate" },
  ];

  // TODO: [KS] replace with actual onSubmit function
  const onSubmit = (data: Record<string, unknown>): void => console.log(data);

  return (
    <form ref={submitFormRef} onSubmit={handleSubmit(onSubmit)}>
      <Box
        sx={{
          display: "flex",
          margin: "0 auto",
          justifyContent: "space-between",
          gap: 2,
          flexWrap: "wrap",
        }}
      >
        {formFields.map((field, index) => (
          <Box key={index} sx={{ minWidth: "45%" }}>
            <Box>{field}</Box>
            <TextField
              sx={{ width: "100%" }}
              {...register(`${field}`, { required: false })}
            />
          </Box>
        ))}

        {datePickers.map(({ name, label }, index) => (
          <Box key={index} sx={{ minWidth: "45%" }}>
            <Box>{label}</Box>
            <DatePicker
              sx={{
                width: "100%",
              }}
              onChange={(date) => setValue(name, date)}
              label={label}
              format="dd-MM-yyyy"
            />
          </Box>
        ))}
      </Box>
    </form>
  );
}
