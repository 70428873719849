import type { Exclusive } from "../../types";

import React, { ReactNode, useState } from "react";

import Button, { ButtonProps } from "@mui/material/Button";
import Dialog, { DialogProps } from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";

import CustomDialogTitle from "./CustomDialogTitle";

export type CustomDialogProps = {
  dialog: {
    onClose?: () => void;
    title: string;
    description?: string;
    content?: ReactNode;
    actions?: (props: { closeDialog: () => void }) => ReactNode;
    dialogProps?: Omit<DialogProps, "open" | "onClose">;
  };
} & Exclusive<
  {
    /**
     * Per default a button is rendered to trigger the dialog
     * Here you can adjust the buttonProps
     */
    buttonProps: Omit<ButtonProps, "onClick">;
  },
  {
    /**
     * Renders a custom trigger for the dialog instead of the buttonProps
     * @param props
     */
    renderTrigger: (props: {
      onClick: (e: React.MouseEvent<unknown>) => void;
    }) => ReactNode;
  }
>;

export default function CustomDialog({
  buttonProps,
  dialog,
  renderTrigger,
}: CustomDialogProps): ReactNode {
  const [isOpen, setIsOpen] = useState(false);

  function closeDialog(): void {
    if (typeof dialog.onClose === "function") {
      dialog.onClose();
    }
    setIsOpen(false);
  }

  function openDialog(e: React.MouseEvent<unknown>): void {
    e.stopPropagation();
    setIsOpen(true);
  }

  const trigger = renderTrigger && renderTrigger({ onClick: openDialog });

  return (
    <>
      {!trigger && <Button {...buttonProps} onClick={openDialog} />}

      {trigger}
      <Dialog {...dialog.dialogProps} open={isOpen} onClose={closeDialog}>
        <CustomDialogTitle
          title={dialog.title}
          description={dialog.description}
          onClose={closeDialog}
        />
        {dialog.content && <DialogContent>{dialog.content}</DialogContent>}
        {dialog.actions && dialog.actions({ closeDialog })}
      </Dialog>
    </>
  );
}
