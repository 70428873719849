import { Exclusive } from "../../types";

import { ReactNode } from "react";

import { Divider, Typography } from "@mui/material";
import Box from "@mui/material/Box";

type Props = {
  title: string;
} & Exclusive<{ description?: string }, { hasDivider?: boolean }>;

export default function CustomTitle({
  title,
  description,
  hasDivider,
}: Props): ReactNode {
  return (
    <Box>
      {title && <Typography variant="h6">{title}</Typography>}
      {description && (
        <Typography variant="subtitle1">{description}</Typography>
      )}
      {hasDivider && <Divider />}
    </Box>
  );
}
