import { grey } from "@mui/material/colors";
import { alpha, createTheme, responsiveFontSizes } from "@mui/material/styles";
import { PaletteOptions } from "@mui/material/styles/createPalette";

import "./fonts/fonts.css";

declare module "@mui/material/InputBase" {
  interface InputBasePropsSizeOverrides {
    extraSmall: true;
  }
}

declare module "@mui/material/TextField" {
  interface TextFieldPropsSizeOverrides {
    extraSmall: true;
  }
}

export const customPalette = {
  primary: {
    main: "#8dbe1e",
    light: alpha("#8dbe1e", 0.15),
    dark: "#6a9e1a",
    contrastText: "#ffffff",
  },
  secondary: {
    main: "#454544",
    light: "#dddee0",
  },
} satisfies PaletteOptions;

export const customTheme = createTheme({
  palette: customPalette,
  typography: {
    button: {
      fontSize: "1rem",
      textTransform: "none",
    },
    fontFamily: "DINPro,Helvetica,Arial,sans-serif",
  },
  shape: {
    borderRadius: 4,
  },
  components: {
    MuiAccordion: {
      defaultProps: {},
      styleOverrides: {
        root: {
          gridColumnStart: "span 12",

          boxShadow: "none",
          "&::before": {
            backgroundColor: "unset",
          },
        },
      },
    },
    MuiAccordionDetails: {
      defaultProps: {},
      styleOverrides: {
        root: {
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    MuiAccordionSummary: {
      defaultProps: {},
      styleOverrides: {
        root: {
          marginTop: "1rem",
          minHeight: "2rem",
          borderBottom: "1px solid",
          borderColor: customPalette.primary.main,
          "&.Mui-expanded": {
            minHeight: "2rem",
            borderBottom: "1px solid",
            borderColor: grey[300],
          },
        },
        content: {
          margin: "0.25rem 0",
          justifyContent: "space-between",
          "&.Mui-expanded": {
            margin: "0.25rem 0",
          },
        },
      },
    },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: 9999,
        },
      },
    },
    MuiButton: {
      defaultProps: {
        variant: "text",
        disableRipple: true,
      },
      variants: [
        {
          props: { variant: "outlined" },
          style: { borderColor: customPalette.primary.main },
        },
        {
          props: { variant: "text" },
          style: {
            "&:hover": {
              color: customPalette.primary.main,
              backgroundColor: "transparent",
            },
          },
        },
      ],
      styleOverrides: {
        root: {
          lineHeight: 1.5,
          padding: "0.325rem 2.5rem",
          color: customPalette.secondary.main,
          transition: "all 0.1s ease",
        },
        startIcon: {
          position: "absolute",
          left: "1.25rem",
        },
        endIcon: {
          position: "absolute",
          right: "1.25rem",
        },
        iconSizeLarge: {
          "& > *:first-of-type": {
            fontSize: "2rem",
          },
        },
      },
    },
    MuiButtonBase: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          scrollbarColor: customPalette.primary.main,
          "&::-webkit-scrollbar, & *::-webkit-scrollbar": {
            backgroundColor: customPalette.primary.light,
            width: 12,
          },
          "&::-webkit-scrollbar-thumb, & *::-webkit-scrollbar-thumb": {
            borderRadius: "4px",
            minHeight: 24,
            backgroundColor: customPalette.primary.main,
            border: customPalette.primary.main,
          },
          "&::-webkit-scrollbar-thumb:focus, & *::-webkit-scrollbar-thumb:focus":
            {
              backgroundColor: customPalette.primary.dark,
            },
          "&::-webkit-scrollbar-thumb:active, & *::-webkit-scrollbar-thumb:active":
            {
              backgroundColor: customPalette.primary.dark,
            },
          "&::-webkit-scrollbar-thumb:hover, & *::-webkit-scrollbar-thumb:hover":
            {
              backgroundColor: customPalette.primary.dark,
            },
          "&::-webkit-scrollbar-corner, & *::-webkit-scrollbar-corner": {
            backgroundColor: customPalette.primary.light,
          },
        },
      },
    },
    MuiDialog: {
      defaultProps: {
        fullWidth: true,
        maxWidth: "sm",
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "1.5rem",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          "& .MuiInputBase-root": {
            width: "100%",
          },
        },
      },
    },
    MuiDialogTitle: {
      styleOverrides: {
        root: {
          "& .MuiIconButton-root": {
            position: "absolute",
            right: 8,
            top: 8,
          },
          "& .MuiTypography-subtitle1": {
            padding: "2rem 0 0 0",
          },
          "& .MuiSvgIcon-root": { fontSize: "2rem" },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: customPalette.secondary.main,
          "&:hover": {
            color: customPalette.primary.main,
            backgroundColor: "transparent",
          },
        },
      },
    },
    MuiInputBase: {
      variants: [
        {
          props: { size: "extraSmall" },
          style: {
            height: "2.25rem",
          },
        },
      ],
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          elevation: 1,
        },
        elevation8: {
          boxShadow: `0px 0px 10px 0px ${customPalette.primary.main}`,
          transition: "box-shadow 0.5s ease-in-out",
        },
      },
    },
    MuiRadio: {
      styleOverrides: {
        root: {
          padding: "0.5rem 0.25rem",
        },
      },
    },
    MuiSelect: {
      defaultProps: {
        variant: "outlined",
        size: "small",
      },
    },
    MuiSlider: {
      styleOverrides: {
        root: {
          "& .MuiSlider-thumb": {
            "&:hover, &:focus-within": {
              boxShadow: `0px 0px 0px 2px ${customPalette.primary.light}`,
            },
            transition: "none",
          },
          "& .MuiSlider-track": {
            transition: "none",
          },
          "& .MuiSlider-mark": {
            transition: "none",
            color: customPalette.secondary.main,
          },
          "& .MuiSlider-rail": {
            color: customPalette.secondary.light,
            transition: "none",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: "outlined",
        size: "small",
      },
      styleOverrides: {
        root: {
          "& label": { display: "none" },
          "& legend": { display: "none", width: "unset" },
          "& fieldset": { top: 0 },
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        arrow: {
          "&:before": {
            border: `1px solid ${customPalette.secondary.light}`,
          },
          color: customPalette.primary.contrastText,
        },
        tooltip: {
          boxShadow: `1px 1px 1px 0px ${customPalette.secondary.light}`,
          fontSize: "0.875rem",
          fontWeight: 400,
          border: `1px solid ${customPalette.secondary.light}`,
          borderRadius: "4px",
          padding: "0.5rem 0.75rem",
          backgroundColor: customPalette.primary.contrastText,
          color: customPalette.secondary.main,
        },
      },
    },
  },
});

export const theme = responsiveFontSizes(customTheme);
