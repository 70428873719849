import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import RadioComponent from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import {
  AttributeControlProps,
  useAttributeLayout,
  useVisibleAttributeValues,
} from "@plansoft-configuration-renderer/components";
import {
  CsValueController,
  useCsAttribute,
  useCsAttributeApi,
  useCsValue,
  useLocalOptionSelected,
} from "@plansoft-configuration-renderer/framework";

export function CustomRadios(props: AttributeControlProps): JSX.Element {
  const attribute = useCsAttribute();
  const values = useVisibleAttributeValues();

  const { fullWidth } = useAttributeLayout();

  const radioGroupName = `radio-name-${attribute.id}`;

  return (
    <FormControl component="fieldset" fullWidth={fullWidth}>
      <RadioGroup name={radioGroupName} row>
        {values.map((v) => (
          <CsValueController value={v} key={v.id}>
            <CustomRadioOption>{props.children}</CustomRadioOption>
          </CsValueController>
        ))}
      </RadioGroup>
    </FormControl>
  );
}

function CustomRadioOption(props: AttributeControlProps): JSX.Element {
  const value = useCsValue();
  const attribute = useCsAttribute();
  const isSelected = useLocalOptionSelected();
  const { toggleOption } = useCsAttributeApi();

  const radioGroupName = `radio-name-${attribute.id}`;
  const radioValue = `radio-value-${attribute.id}-${value.value}`;
  const radioId = `radio-id-${attribute.id}-${value.value}`;

  const isDisabled = Boolean(
    !attribute.isAllowed ||
      attribute.isReadOnly ||
      !value.isAllowed ||
      !value.isActive,
  );

  return (
    <FormControlLabel
      id={radioId}
      name={radioGroupName}
      value={radioValue}
      control={<RadioComponent disabled={isDisabled} />}
      disabled={isDisabled}
      checked={isSelected}
      onChange={() => toggleOption(value.value, !value.value.selected)}
      label={<>{props.children}</>}
      disableTypography
      sx={{ width: "25%", margin: 0 }}
    />
  );
}
