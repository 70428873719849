export const de_DE = {
  translation: {
    add_to_cart: "In Warenkorb",
    save: "Speichern",
    load: "Laden",
    title: "Profilschienenführung individuell konfigurieren",
    type_key: "Typenschlüssel",
    start: "Start",
    load_configuration_dialog: {
      title: "Konfiguration laden - Suchparameter setzen",
      cancel: "Abbrechen",
      search: "Suchen",
      startDate: "Erstelldatum von",
      endDate: "bis",
    },
  },
};
