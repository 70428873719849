import { Suspense, lazy } from "react";

import FormControl from "@mui/material/FormControl";
import {
  AttributeControlProps,
  useAttributeLayout,
} from "@plansoft-configuration-renderer/components";

const CustomSelectMapper = lazy(() => import("./CustomSelectMapper"));

export interface CustomLayoutProps {
  custom: {
    slider?: boolean;
  };
}

/**
 * Lazy loads select control
 * @param props
 * @returns
 */
export function CustomSelect(
  props: AttributeControlProps & Partial<CustomLayoutProps>,
): JSX.Element {
  const { fullWidth = false } = useAttributeLayout();

  return (
    <Suspense>
      <FormControl fullWidth={fullWidth}>
        <CustomSelectMapper {...props}>{props.children}</CustomSelectMapper>
      </FormControl>
    </Suspense>
  );
}
