import {
  AttributeBaseType,
  AttributeComponentType,
  AttributeComponents,
  AttributeStrategy,
  ConfigurationComponentType,
  ConfigurationComponents,
  ControlComponents,
  LayoutComponentProvider,
  LayoutControlType,
} from "@plansoft-configuration-renderer/components";

import { CustomAttributeHeadline } from "./attributes/CustomAttributeHeadline";
import { CustomAttributeWrapper } from "./attributes/CustomAttributeWrapper";
import {
  CustomConfigurationTabItem,
  CustomConfigurationTabs,
} from "./components/CustomConfigurationTabs";
import { CustomRadios } from "./controls/CustomRadios";
import { CustomSelect } from "./controls/CustomSelect";

// override control components for attributes
const controlComponents = {
  ...ControlComponents,
  [LayoutControlType.Radios]: CustomRadios,
  [LayoutControlType.Select]: CustomSelect,
};

// override configuration level components
const configurationComponents = {
  ...ConfigurationComponents,
  [ConfigurationComponentType.ConfigurationTabs]: CustomConfigurationTabs,
  [ConfigurationComponentType.ConfigurationTabItem]: CustomConfigurationTabItem,
};

// override attribute components
const attributeComponents = {
  ...AttributeComponents,
  [AttributeComponentType.AttributeHeadline]: CustomAttributeHeadline,
  [AttributeBaseType.SingleOption]: CustomAttributeWrapper,
  [AttributeBaseType.MultiOption]: CustomAttributeWrapper,
  [AttributeBaseType.Substructure]: CustomAttributeWrapper,
  [AttributeBaseType.Editable]: CustomAttributeWrapper,
};

export function CustomRenderer(): JSX.Element {
  // see properties of LayoutComponentProvider for more information
  return (
    <LayoutComponentProvider
      configurationComponents={configurationComponents}
      attributeComponents={attributeComponents}
      controlComponents={controlComponents}
    >
      {/* Attribute strategy renders the components using the layout information */}
      <AttributeStrategy />
    </LayoutComponentProvider>
  );
}
